/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: Roboto;
  src: url(assets/Roboto-Regular.ttf) format("truetype");
}
/* @import "../node_modules/ng-zorro-antd/index.less"; */

@collapse-content-padding: 0px;
.ant-menu {
  background: #017457;
  color: aliceblue;
}
.ant-menu-sub.ant-menu-inline {
  background: #017457;
}
.ant-menu-item a {
  color: aliceblue;
}
.ant-menu-item-selected {
  color: #1890ff;
  background: #017457;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #85bc91;
  color: #1890ff;
}
